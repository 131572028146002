
import { firestore } from "@/firebase";
import { ROOT_ACTIONS } from "@/store/actions";
import { Activity, ActualActivity, Phase, StateType } from "@/types";
import { format } from "date-fns";
import {
  collection,
  getDoc,
  getDocs,
  query,
  where,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Home extends Vue {
  rows = [] as any[];
  headers = [
    { text: "Naam", value: "name", groupable: false },
    { text: "Startdatum", value: "startDate", groupable: false },
    { text: "Status", value: "status" },
    { text: "Fase 1", value: "phase1", groupable: false },
    { text: "Fase 2", value: "phase2", groupable: false },
    { text: "Fase 3", value: "phase3", groupable: false },
    { text: "Uren Tot.", value: "totalHours", groupable: false },
    { text: "Aanwzg", value: "present", groupable: false },
    { text: "Bevestigd", value: "confirmed", groupable: false },
    { text: "Niet Bev.", value: "notConfirmed", groupable: false },
    { text: "Coach", value: "coach" },
  ];

  loading = true;

  async mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, []);

    // Fetch everything in bulk
    const programsQuery = query(
      collection(firestore, "programs"),
      where("isArchived", "!=", true)
    );

    const programsSnapshot = await getDocs(programsQuery);

    const participantsSnapshot = await getDocs(collection(firestore, "users"));
    const coachesSnapshot = await getDocs(collection(firestore, "users"));

    const phasesQuery = query(
      collection(firestore, "phases"),
      orderBy("start", "asc")
    );
    const phasesSnapshot = await getDocs(phasesQuery);

    const activitiesQuery = query(collection(firestore, "activities"));
    const activitiesSnapshot = await getDocs(activitiesQuery);

    const actualActivitiesQuery = query(
      collection(firestore, "actual-activities")
    );
    const actualActivitiesSnapshot = await getDocs(actualActivitiesQuery);

    // Index data for faster lookups
    const participantsMap = new Map();
    participantsSnapshot.docs.forEach((doc) =>
      participantsMap.set(doc.id, doc.data())
    );

    const coachesMap = new Map();
    coachesSnapshot.docs.forEach((doc) => coachesMap.set(doc.id, doc.data()));

    const phasesMap = new Map();
    phasesSnapshot.docs.forEach((doc) => {
      const phaseData = doc.data();
      if (!phasesMap.has(phaseData.programId)) {
        phasesMap.set(phaseData.programId, []);
      }
      phasesMap.get(phaseData.programId).push({ ...phaseData, id: doc.id });
    });

    const activitiesMap = new Map();
    activitiesSnapshot.docs.forEach((doc) => {
      const activityData = doc.data();
      if (!activitiesMap.has(activityData.phaseId)) {
        activitiesMap.set(activityData.phaseId, []);
      }
      activitiesMap
        .get(activityData.phaseId)
        .push({ ...activityData, id: doc.id });
    });

    const actualActivitiesMap = new Map();
    actualActivitiesSnapshot.docs.forEach((doc) => {
      const actualActivityData = doc.data();
      if (!actualActivitiesMap.has(actualActivityData.activityId)) {
        actualActivitiesMap.set(actualActivityData.activityId, []);
      }
      actualActivitiesMap
        .get(actualActivityData.activityId)
        .push({ ...actualActivityData, id: doc.id });
    });

    // Process programs
    for (const programDoc of programsSnapshot.docs) {
      const programData = programDoc.data();
      if (programData.state === StateType.SUSPENDED) {
        continue;
      }
      const participant = participantsMap.get(programData.participant);
      const coach = coachesMap.get(programData.coach);

      if (!participant || !coach) {
        continue; // Skip if participant or coach doesn't exist
      }

      // const programPhases = phasesMap.get(programDoc.id) || [];
      // programPhases.sort(
      //   (a: any, b: any) => a.start.toDate() - b.start.toDate()
      // );

      // const [firstPhase, secondPhase, thirdPhase] = programPhases;

      // if (!firstPhase) {
      //   continue;
      // }

      // let currentPhase = "Einde traject";
      // const now = new Date();

      // if (firstPhase.start.toDate() < now && firstPhase.end.toDate() > now) {
      //   currentPhase = "Fase 1";
      // } else if (
      //   secondPhase?.start.toDate() < now &&
      //   secondPhase?.end.toDate() > now
      // ) {
      //   currentPhase = "Fase 2";
      // } else if (
      //   thirdPhase?.start.toDate() < now &&
      //   thirdPhase?.end.toDate() > now
      // ) {
      //   currentPhase = "Fase 3";
      // }

      // const phasesToCheck = [firstPhase, secondPhase, thirdPhase].filter(
      //   Boolean
      // );
      // const plannedHoursPhases = Array(phasesToCheck.length).fill(0);

      // const actualHoursPhases = Array(phasesToCheck.length).fill(0);
      // const confirmedHoursPhases = Array(phasesToCheck.length).fill(0);
      // const nonConfirmedHoursPhases = Array(phasesToCheck.length).fill(0);
      // const phaseWarnings = Array(phasesToCheck.length).fill(false);

      // for (const [index, phase] of phasesToCheck.entries()) {
      //   const activitiesForPhase = activitiesMap.get(phase.id) || [];
      //   const activitiesPlanned = activitiesForPhase.reduce(
      //     (acc: any, curr: any) => acc + curr.plannedHours,
      //     0
      //   );

      //   const totalPlannedHoursByAdmin = activitiesForPhase.reduce(
      //     (acc: any, curr: any) => acc + curr.plannedHours,
      //     0
      //   );

      //   plannedHoursPhases[index] += totalPlannedHoursByAdmin;
      //   let plannedHoursByCoach = 0;
      //   for (const activity of activitiesForPhase) {
      //     const actualActivities = actualActivitiesMap.get(activity.id) || [];
      //     // sum all actual hours for actual activities

      //     plannedHoursByCoach += actualActivities.reduce(
      //       (acc: any, curr: any) => acc + curr.actualHours,
      //       0
      //     );
      //     const presentHours = actualActivities
      //       .filter((e: any) => e.participantPresent)
      //       .reduce((acc: any, curr: any) => acc + curr.actualHours, 0);

      //     const confirmedHours = actualActivities
      //       .filter((e: any) => e.participantConfirmed)
      //       .reduce((acc: any, curr: any) => acc + curr.actualHours, 0);

      //     actualHoursPhases[index] += presentHours;
      //     confirmedHoursPhases[index] += plannedHoursByCoach;
      //     nonConfirmedHoursPhases[index] += presentHours - confirmedHours;

      //     if (
      //       totalPlannedHoursByAdmin * 1.2 < presentHours &&
      //       phase.end.toDate() < now
      //     ) {
      //       phaseWarnings[index] = true;
      //     }
      //   }
      // }

      // const totalConfirmedHours = confirmedHoursPhases.reduce(
      //   (acc, curr) => acc + curr,
      //   0
      // );

      // const totalNonConfirmedHours = nonConfirmedHoursPhases.reduce(
      //   (acc, curr) => acc + curr,
      //   0
      // );

      // const totalPresentHours = actualHoursPhases.reduce(
      //   (acc, curr) => acc + curr,
      //   0
      // );

      // //   warning if 20% or more hours are not confirmed
      // const warning = totalNonConfirmedHours / totalPresentHours >= 0.2;

      const getPhaseText = (phaseIndex: number) => {
        // get the total number of actualHours from all actual activities for this phase
        const phasesInThisProgram = phasesMap.get(programDoc.id) || [];

        if (phaseIndex >= phasesInThisProgram.length) {
          return "";
        }

        let hoursRequired = 0;
        let hoursActual = 0;

        const phase = phasesInThisProgram[phaseIndex] as Phase;

        const activitiesForPhase = activitiesMap.get(phase.id) || [];
        activitiesForPhase.forEach((activity: Activity) => {
          hoursRequired += activity.plannedHours || 0;
          const actualActivities = actualActivitiesMap.get(activity.id) || [];
          actualActivities.forEach((actualActivity: ActualActivity) => {
            hoursActual += actualActivity.actualHours;
          });
        });
        // text is the actual hours / planned hours, beware of null / undefined
        return `${isNaN(hoursActual) ? 0 : Math.round(hoursActual)} / ${Math.round(hoursRequired)}`;
      };

      const getHoursTotal = () => {
        // return the same as getPhaseText but for all phases
        const phasesInThisProgram = phasesMap.get(programDoc.id) || [];
        let hoursRequired = 0;
        let hoursActual = 0;
        phasesInThisProgram.forEach((phase: Phase) => {
          const activitiesForPhase = activitiesMap.get(phase.id) || [];
          activitiesForPhase.forEach((activity: Activity) => {
            hoursRequired += activity.plannedHours || 0;
            const actualActivities = actualActivitiesMap.get(activity.id) || [];
            actualActivities.forEach((actualActivity: ActualActivity) => {
              hoursActual += actualActivity.actualHours;
            });
          });
        });

        return `${isNaN(hoursActual) ? 0 : Math.round(hoursActual)} / ${Math.round(hoursRequired)}`;
      }

      const getFirstPhase = () => {
        const phasesInThisProgram = phasesMap.get(programDoc.id) || [];
        return phasesInThisProgram[0];
      };

      const getCurrentPhase = () => {
        let currentPhase = "Einde traject";
        const now = new Date().getTime();
        const phasesInThisProgram = phasesMap.get(programDoc.id) || [];
        for (const phase of phasesInThisProgram) {
          if (
            phase.start.toDate().getTime() < now &&
            phase.end.toDate().getTime() > now
          ) {
            currentPhase = `Fase ${phase.title.match(/\d+/)[0]}`;
            break;
          }
        }
        return currentPhase;
      };

      const getHoursPresent = () => {
        // return all hours where actualActivity.participantPresent is true
        const phasesInThisProgram = phasesMap.get(programDoc.id) || [];
        let hoursPresent = 0;
        phasesInThisProgram.forEach((phase: Phase) => {
          const activitiesForPhase = activitiesMap.get(phase.id) || [];
          activitiesForPhase.forEach((activity: Activity) => {
            const actualActivities = actualActivitiesMap.get(activity.id) || [];
            actualActivities.forEach((actualActivity: ActualActivity) => {
              if (actualActivity.participantPresent) {
                hoursPresent += actualActivity.actualHours;
              }
            });
          });
        });
        return isNaN(hoursPresent) ? 0 : Math.round(hoursPresent);
      }

      const getHoursConfirmed = () => {
        // return all hours where actualActivity.participantConfirmed is true
        const phasesInThisProgram = phasesMap.get(programDoc.id) || [];
        let hoursConfirmed = 0;
        phasesInThisProgram.forEach((phase: Phase) => {
          const activitiesForPhase = activitiesMap.get(phase.id) || [];
          activitiesForPhase.forEach((activity: Activity) => {
            const actualActivities = actualActivitiesMap.get(activity.id) || [];
            actualActivities.forEach((actualActivity: ActualActivity) => {
              if (actualActivity.participantConfirmed) {
                hoursConfirmed += actualActivity.actualHours;
              }
            });
          });
        });
        return isNaN(hoursConfirmed) ? 0 : Math.round(hoursConfirmed);
      }

      const getHoursNonConfirmed = () => {
        // return all hours where actualActivity.participantConfirmed is false
        const phasesInThisProgram = phasesMap.get(programDoc.id) || [];
        let hoursNonConfirmed = 0;
        phasesInThisProgram.forEach((phase: Phase) => {
          const activitiesForPhase = activitiesMap.get(phase.id) || [];
          activitiesForPhase.forEach((activity: Activity) => {
            const actualActivities = actualActivitiesMap.get(activity.id) || [];
            actualActivities.forEach((actualActivity: ActualActivity) => {
              if (actualActivity.participantPresent && !actualActivity.participantConfirmed) {
                hoursNonConfirmed += actualActivity.actualHours;
              }
            });
          });
        });
        return isNaN(hoursNonConfirmed) ? 0 : Math.round(hoursNonConfirmed);
      }

      const phaseIsWarning = (phaseIndex: number) => {
        // return true if the phase is over and the actual hours are less than 75% of the planned hours
        const phasesInThisProgram = phasesMap.get(programDoc.id) || [];

        if (phaseIndex >= phasesInThisProgram.length) {
          return false;
        }

        const phase = phasesInThisProgram[phaseIndex] as Phase;

        if(phase.end.toDate().getTime() > new Date().getTime()) {
          return false;
        }

        const activitiesForPhase = activitiesMap.get(phase.id) || [];
        let hoursRequired = 0;
        let hoursActual = 0;
        activitiesForPhase.forEach((activity: Activity) => {
          hoursRequired += activity.plannedHours || 0;
          const actualActivities = actualActivitiesMap.get(activity.id) || [];
          actualActivities.forEach((actualActivity: ActualActivity) => {
            hoursActual += actualActivity.actualHours;
          });
        });

        return hoursActual < hoursRequired * 0.75;
      };

      const confirmedHoursWarning = () => {
        // return true if 20% or actual hours are not confirmed

        const phasesInThisProgram = phasesMap.get(programDoc.id) || [];
        let hoursPresent = 0;
        let hoursConfirmed = 0;
        phasesInThisProgram.forEach((phase: Phase) => {
          const activitiesForPhase = activitiesMap.get(phase.id) || [];
          activitiesForPhase.forEach((activity: Activity) => {
            const actualActivities = actualActivitiesMap.get(activity.id) || [];
            actualActivities.forEach((actualActivity: ActualActivity) => {
              if (actualActivity.participantPresent) {
                hoursPresent += actualActivity.actualHours;
              }
              if (actualActivity.participantConfirmed) {
                hoursConfirmed += actualActivity.actualHours;
              }
            });
          });
        });

        return hoursConfirmed / hoursPresent < 0.8;
      }

      this.rows.push({
        programId: programDoc.id,
        name: `${participant.firstname} ${participant.lastname}`,
        startDate: format(getFirstPhase().start.toDate(), "dd/MM/yyyy"),
        status: getCurrentPhase(),
        phase1: getPhaseText(0),
        phase2: getPhaseText(1),
        phase3: getPhaseText(2),
        phase1Warning: phaseIsWarning(0),
        phase2Warning: phaseIsWarning(1),
        phase3Warning: phaseIsWarning(2),
        totalHours: getHoursTotal(),
        present: getHoursPresent(),
        confirmed: getHoursConfirmed(),
        notConfirmed: getHoursNonConfirmed(),
        coach: `${coach.firstname} ${coach.lastname}`,
        confirmedHoursWarning: confirmedHoursWarning(),
      });
    }
    this.loading = false;
  }

  getItemClass(item: any) {
    if (item.confirmedHoursWarning) {
      return "red lighten-5";
    }
  }

  exportAsCsv() {
    const header = Object.keys(this.rows[0]).join(",");
    const csv = this.rows.map((row) => Object.values(row).join(",")).join("\n");
    const blob = new Blob([header + "\n" + csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "export.csv";
    a.click();
  }
}
